import axios from "axios";
import React, { useEffect, useState } from "react";
import '../billing/insightsManik/insightsCss.css';
import { getLocalStorageValue } from "../billing/insightsManik/util/util";
import editIcon from "../billing/insightsManik/assets/editIcon.svg"
import calendar from "../billing/insightsManik/assets/calendar.svg"
import cross from "../billing/insightsManik/assets/cross.svg"
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { CircularProgress, ListItemText } from '@mui/material';
import Select, { components } from "react-select";
import arrowdown from "../billing/insightsManik/assets/arrow-down.svg";
import TableModel from '../../components/tableModel/tablemodel';
import CalenderComponent from '../../components/CalenderComponent';
import CustomSelect from "../billing/insightsManik/components/CustomSelect";
import ReactPaginate from "react-paginate";
import leftArrow from "../billing/insightsManik/assets/leftArrow.svg";
import rightArrow from "../billing/insightsManik/assets/RightArrow.svg";
import whiteArrow from "../billing/insightsManik/assets/whiteArrow.svg";
import blueArrow from "../billing/insightsManik/assets/blueArrow.svg";
import back from "../billing/insightsManik/assets/back.svg";
import * as Constants from '../../data/constants';
import { getManager } from '../../apis/dropdown/dropdowns';
import { useForceUpdate } from "../../util/util";
import PercentageDiv from '../billing/insightsManik/components/Percentagediv';
import List from '@mui/material/List';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Alert from '@mui/material/Alert';
const ManagerDataEntry = () => {
    const user_id = getLocalStorageValue('adminPanelLoginData').userdata.id;
    const token = getLocalStorageValue("adminPanelLoginData").token;
    const employee_type = getLocalStorageValue('adminPanelLoginData').userdata.employee_type;
    const [open, setOpen] = useState(false);
    const [isModalOpen, setisModalOpen] = useState(false);
    const [selectedRangedata, setSelectedRangedata] = useState({ startDate: new Date(), endDate: new Date() });
    const [dayLabel, setDayLabel] = useState("Last 30 days");
    const [initial, setInitial] = useState(true);
    const [itemsOnPage, setItemsOnPage] = useState(10);
    const [manager, setManager] = useState("");
    const [showAM, setShowAM] = useState(false);
    const [hasSubTeam, setHasSubTeam] = useState(false)
    const[singleAM,setSingleAM]=useState(false);
    const[singleAMId,setSingleAMId]=useState("");
    const [alert,setAlert]=useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const [offset, setOffset] = useState(0);
    const getFormattedDate = (date) => {
        var y = date.getFullYear()
        var m = date.getMonth() + 1;
        var d = date.getDate()
        return `${y}-${m}-${d}`;
    }
    const handleClose = () => setOpen(false);
    const yes_date=new Date()
    yes_date.setDate(yes_date.getDate()-1);
    const [date, setDate] = useState(yes_date.toISOString().substring(0, 10));
    const [amount, setAmount] = useState();
    const onDateChange = (event) => {
        setDate(event.target.value);
    };
    const [targetRevenue, setTargetRevenue] = useState(0);
    const [disableSubmit, setdisableSubmit] = useState();
    const [dateRange, setDateRange] = useState("Last 30 Days");
    const [showSubTeam, setShowSubTeam] = useState(false);
    const [tableData, setTableData] = useState({
        last_30_days: [],
        last_12_weeks: [],
        last_12_months: []
    });

    const [forecastData, setForecastData] = useState({
        last_30_days: { target_revenue: 0, revenue_earned: 0, underachieved: 0, daily_earning_rate: 0, weekly_earning_rate: 0 },
        last_12_weeks: { target_revenue: 0, revenue_earned: 0, underachieved: 0, daily_earning_rate: 0, weekly_earning_rate: 0 },
        last_12_months: { target_revenue: 0, revenue_earned: 0, underachieved: 0, daily_earning_rate: 0, weekly_earning_rate: 0 },
    });
    const [totalRowData, setTotalRowData] = useState({
        last_30_days: [],
        last_12_weeks: [],
        last_12_months: []
    });
    const is_gm = employee_type == "general_manager";
    var totalobj = { date: "Total", target_revenue: targetRevenue, target_achieved: 10, progress: 10 }
    const [totalRow, setTotalRow] = useState([totalobj]);
    const [selectedTable, setSelectedTable] = useState(tableData.last_30_days);
    const [revenueEarned, setRevenueEarned] = useState(forecastData.last_30_days.revenue_earned);
    const [underachieved, setUnderachieved] = useState(forecastData.last_30_days.underachieved);
    const [dailyRate, setdailyRate] = useState(forecastData.last_30_days.daily_earning_rate);
    const [weeklyRate, setweeklyRate] = useState(forecastData.last_30_days.weekly_earning_rate);
    const [forecastTarget, setForecastTarget] = useState(forecastData.last_30_days.target_revenue);
    const [teamData, setTeamData] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [optionsManagersList, setOptionsManagerList] = useState([])
    const [allManagerList,setAllManagerList]=useState([])
    const [logData,setLogData]=useState([])
    const [showLogModal,setShowLogModal]=useState(false)
    const[index,setIndex]=useState(0)
    const[xval,setxval]=useState(0)
    const[yval,setyval]=useState(0)
    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "26%",
        height: is_gm ? "35%" : "30%",
        bgcolor: "background.paper",
        overflowY: "auto",
        boxShadow: 24,
        p: 2,
        padding: "0",
        borderRadius: "20px"
    };
    const modalStyle1 = {
        position: "absolute",
        top: yval-50,
        left: xval,
        transform: "translate(-50%, -50%)",
        width: "15%",
        bgcolor: "background.paper",
        overflowY: "auto",
        boxShadow: 24,
        p: 2,
        padding: "5",
        borderRadius: "5px"
    };

    const obj = {
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "May",
        6: "Jun",
        7: "Jul",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec"
    };


    const targetMap = new Map();
    for (let i = 0; i < teamData.length; i++) {
        if (teamData[i]) {
            targetMap.set((teamData[i].user_id).toString(), teamData[i].target_revenue);
        }
    }
    const getAllManagers = async () => {
        const list = await getManager()
        setAllManagerList(list)
        setOptionsManagerList(list)
        return list;

    }
    const allManagerMap = new Map();
    for (let i = 0; i < allManagerList.length; i++) {
        if (allManagerList[i]) {
            allManagerMap.set(allManagerList[i].value, allManagerList[i].label.split(' - ')[0]);
        }
    }
    const refreshComponent = useForceUpdate()

    const [columnHeadings, setColumnHeadings] = useState([])
    const [loader, setLoader] = useState(true)

    const usertypeComponent = (valueItem, item) => {
        const keys = Object.keys(valueItem);
        const data = valueItem[item.value] ? valueItem[item.value] : valueItem[keys] ?
            valueItem[keys][item.value] : 0;
        var user=typeof item.value==="number"?item.value:user_id
        var logs=valueItem[`${user}-logs`]?valueItem[`${user}-logs`]:  valueItem[keys]?valueItem[keys]["logs"]:valueItem["logs"];
        if (valueItem[item.value] === "Total") {
            return <div style={{
                // fontFamily: "kanit",
                display:'flex',
                alignItems:'center',
                justifyContent:item.label==="Date"?'left':'right',
                fontSize: "14px",
                paddingLeft: "1.3rem",
                textAlign:"right",
            }}>Total</div>;
        }
        else if (item.value === "progress") {
            return <div style={{textAlign:"right",paddingRight: "1rem",}}>
            <PercentageDiv percentage={Math.round(data)} />
            </div>
        }
        return (
            <div
                style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display:'flex',
                    paddingRight:'1rem',
                    alignItems:'center',
                    justifyContent:item.label=="Date"?'left':'right',
                    textAlign:"right",
                    color:typeof data==="number" && item.value!="target_revenue" && logs && logs.length>1?"red": valueItem["date"]==="Total"?"white":"black"
                }}
                onClick={(e) => {
                    if(typeof data==="number"){
                    var dd=valueItem.date?valueItem.date:valueItem[keys]["date"]
                    if(!isNaN(new Date(dd).getDate()) && !dd.match("[A-Za-z]+") && item.value!="total_earning" && item.value!="target_revenue" && !item.haschild)
                    showHistory(e.screenX,e.screenY,logs)
                    }
                  }}
            >
                {typeof data === "number" ? Math.round(data) : data}
            </div>
        );
    };
    const setColumnHeadingsCustomFunc = (singleAM,hasSubTeam,teamData,optionsManagersList) => {
        const swappedMap = new Map();
        for (let i = 0; i < optionsManagersList.length; i++) {
            if (optionsManagersList[i]) {
                swappedMap.set(optionsManagersList[i].value, optionsManagersList[i].label.split(' - ')[0]);
            }
        }
        var tempcolumnHeadings = []
        if (hasSubTeam) {
            var headings = [];
            var total = {}
            total["date"] = "Total"
            headings.push(
                { label: 'Date',locked:true, value: 'date', width: 120, hide: false, customComponent: usertypeComponent ,customHeaderComponent:userHeaderComponent,loader,setLoader,showSubTeam,setShowSubTeam},
            )
            for (let i = 0; i < teamData.length; i++) {
                const key = teamData[i].user_id
                const active=teamData[i].active
                var user = swappedMap.get(key.toString())
                if(user==undefined)
                user=teamData[i].username
                if (!teamData[i].sub_team && !teamData[i].other_manager){
                    headings.push(
                        { label: user, value: key, width: 120, hide: active?false:true, customComponent:usertypeComponent,customHeaderComponent:userHeaderComponent,loader,setLoader,showSubTeam,setShowSubTeam  },
                    )
                }
                else{
                    if(teamData[i].sub_team){
                        var subTeam=teamData[i].team_members
                        var child=[]
                        for(let j=0;j<subTeam.length;j++){
                            const key1 = teamData[i].team_members[j].user_id
                            var user1 = swappedMap.get(key1.toString())
                            if(user1==undefined)
                            user1=teamData[i].team_members[j].username
                            const active1=teamData[i].team_members[j].active
                            if(singleAM){
                                headings.push(
                                { label: user1, width: 150, value: key1,hide: active1?false:true,customComponent:usertypeComponent,customHeaderComponent:userHeaderComponent })
                            }
                            else{
                            child.push(
                                { label: user1, width: 150, value: key1,hide: active1?false:true,customComponent:usertypeComponent },
                            )
                            }
                        }
                        if(!singleAM){
                    headings.push(
                        { label: user, width: 120,hide: active?false:true,haschild:true,child:showAM?child:null,value:showAM?null:key,customHeaderComponent:userHeaderComponent,loader,setLoader,showSubTeam,setShowSubTeam,customComponent:showAM?null:usertypeComponent },
                    )
                }
                    }
                }
                total[user] = 0
            }
            headings.push({
                label: 'Total', value: 'total_earning', width: 60, hide: false, customComponent: usertypeComponent,customHeaderComponent:userHeaderComponent,loader,setLoader,showSubTeam,setShowSubTeam
            })
            total["total_earning"] = 0
            tempcolumnHeadings = headings;
        }
        else {
            tempcolumnHeadings = [
                { label: 'Date', value: 'date', width: 120, hide: false, customComponent: usertypeComponent },
                { label: 'Target Earning', value: 'target_revenue', width: 150, hide: false, customComponent: usertypeComponent },
                { label: 'Earning Achieved', value: 'target_achieved', width: 180, hide: false, customComponent: usertypeComponent },
                { label: 'Progress', value: 'progress', width: 175, hide: false, customComponent: usertypeComponent }
            ]
        }
        setColumnHeadings(tempcolumnHeadings)
        setLoader(false)
    }
    useEffect(async() => {

        const allManagers = await getAllManagers()
        await axios.get("".concat(Constants.PROTOCOL,Constants.HOST,"/api_am/get_team/"),
            {
                headers: {
                    Authorization: `${token}`,
                },
                params: { user_id: user_id, is_gm: employee_type === "general_manager" }
            }
        )
        .then((response) => {
            var res = []
            var target = 0;
            var team_ids = [];
            const data = response.data.result;
            const subTeam = response.data.hasSubTeam;
            const managedUsers = response.data.managed_users;
            var singleAM=false;
            var singleAMId=""
            setTeamData(data)
            setHasSubTeam(subTeam)
            setTeamMembers(managedUsers)
            refreshComponent(123)
            for (let i = 0; i < data.length; i++) {
                if (!data[i]["sub_team"] && managedUsers.includes(data[i]["user_id"])){
                    target += data[i]["target_revenue"];
                if(data[i].active)
                team_ids.push(data[i]["user_id"])
                }
                if(is_gm && data[i].sub_team && data[i].team_members.length+1==data.length){
                    setSingleAM(true)
                    setSingleAMId(data[i].user_id)
                    singleAMId=data[i].user_id;
                    singleAM=true
                }
            }
            setColumnHeadingsCustomFunc(singleAM,subTeam,data,allManagers)
            console.log(allManagers,"sneha2134")
            var list =allManagers;
            for (let i = 0; i < list.length; i++) {
                var val = Number(list[i].value)
                if (team_ids.includes(val) && list[i].value!=singleAMId) {
                    res.push(list[i])
                }
            }
            // if(employee_type!="general_manager"){
            setOptionsManagerList(res)
            // }
            setTargetRevenue(target)
        })
        .catch((error) => {
            console.error("Error fetching data:", error);
        });
    }, [showSubTeam]);

    useEffect(() => {
        const API_ENDPOINT = hasSubTeam ?  
        "".concat(Constants.PROTOCOL,Constants.HOST,"/api_am/table_data/"):
        "".concat(Constants.PROTOCOL,Constants.HOST,"/api_am/data/");
        if (initial)
            axios
                .get(API_ENDPOINT, {
                    headers: {
                        Authorization: `${token}`,
                    },
                    params: { user_id:singleAM && is_gm? singleAMId: user_id, manager_id: "", is_gm: is_gm }
                })
                .then((response) => {
                    const tableData = {
                        last_30_days: [],
                        last_12_weeks: [],
                        last_12_months: []
                    };
                    var days_in_month = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
                    const week_earning = response.data["this_week_earning"];
                    const month_earning = response.data["this_month_earning"]
                    if (hasSubTeam) {
                        var tot1 = 0;
                        var tot2 = 0;
                        var tot3 = 0;
                        var tot4 = 0;
                        var total = {};
                        var curr_month = 0;
                        var fData = {
                            last_30_days: { target_revenue: targetRevenue * days_in_month, revenue_earned: 0, underachieved: 0, daily_earning_rate: 0, weekly_earning_rate: 0 },
                            last_12_weeks: { target_revenue: targetRevenue * days_in_month, revenue_earned: 0, underachieved: 0, daily_earning_rate: 0, weekly_earning_rate: 0 },
                            last_12_months: { target_revenue: targetRevenue * days_in_month, revenue_earned: 0, underachieved: 0, daily_earning_rate: 0, weekly_earning_rate: 0 },
                        }
                        const data3 = response.data["table_data"]["last_30_days"];
                        var totalMap = {}
                        totalMap["date"] = "Total"
                        totalMap["total_earning"] = 0
                        for (const key in data3) {
                            var tot = 0;
                            var obj1 = {};
                            if (data3.hasOwnProperty(key)) {
                                obj1["date"] = new Date(key).toLocaleDateString("en-US",)
                                const list = data3[key];
                                for (let i = 0; i < list.length; i++) {
                                    const a = list[i];
                                    a["data"] = Math.round(a["data"])
                                    var user = "" + a["user_id"]
                                    obj1[user] = a["data"]
                                    tot += a["sub_team"]?0:a["data"]
                                    if (new Date(key).getMonth() == new Date().getMonth()) {
                                        if (teamMembers.includes(a["user_id"])) {
                                            curr_month += a["sub_team"]?0:a["data"]
                                        }
                                    }
                                    if (!totalMap.hasOwnProperty(user)) {
                                        totalMap[user] = 0
                                    }
                                    totalMap[user] += a["data"]
                                    var logs;
                                    try{
                                        logs=a["custom_text"]?JSON.parse(a["custom_text"]).logs:[]
                                    }
                                    catch(e){
                                        logs=[]
                                    }
                                    obj1[`${user}-logs`]=logs
                                }
                                obj1["total_earning"] = tot
                                totalMap["total_earning"] += tot
                                tableData.last_30_days.push(obj1)
                            }
                        }
                        total["last_30_days"] = totalMap;
                        fData["last_30_days"]["target_revenue"] = targetRevenue * days_in_month;
                        fData["last_30_days"]["revenue_earned"] = curr_month;
                        fData["last_30_days"]["underachieved"] = fData["last_30_days"]["target_revenue"] - fData["last_30_days"]["revenue_earned"];
                        fData["last_30_days"]["weekly_earning_rate"] = week_earning;
                        fData["last_30_days"]["daily_earning_rate"] = month_earning;
                        const data2 = response.data["table_data"]["last_12_weeks"];
                        totalMap = {}
                        totalMap["date"] = "Total"
                        totalMap["total_earning"] = 0
                        for (const key in data2) {
                            var tot = 0;
                            var obj1 = {};
                            if (data2.hasOwnProperty(key)) {
                                var month = key.split('-')[0]
                                var week = key.split('-')[1]
                                obj1["date"] = obj[month] + "'W" + week
                                const list = data2[key];
                                for (let i = 0; i < list.length; i++) {
                                    const a = list[i];
                                    a["data"] = Math.round(a["data"])
                                    var user = "" + a["user_id"]
                                    obj1[user] = a["data"]
                                    tot += a["sub_team"]?0:a["data"]
                                    if (!totalMap.hasOwnProperty(user)) {
                                        totalMap[user] = 0
                                    }
                                    totalMap[user] += a["data"]
                                }
                                obj1["total_earning"] = tot
                                totalMap["total_earning"] += tot
                                tableData.last_12_weeks.push(obj1)
                            }
                        }
                        total["last_12_weeks"] = totalMap
                        const data1 = response.data["table_data"]["last_12_months"];
                        totalMap = {}
                        totalMap["date"] = "Total"
                        totalMap["total_earning"] = 0
                        for (const key in data1) {
                            var obj1 = {};
                            var tot = 0;
                            if (data1.hasOwnProperty(key)) {
                                var year = key.split('-')[0]
                                var month = key.split('-')[1]
                                obj1["date"] = obj[month] + "'" + year
                                const list = data1[key];
                                for (let i = 0; i < list.length; i++) {
                                    const a = list[i];
                                    a["sum"] = Math.round(a["sum"])
                                    var user = "" + a["user_id"]
                                    obj1[user] = a["sum"]
                                    tot += a["sub_team"]?0:a["sum"]
                                    if (!totalMap.hasOwnProperty(user)) {
                                        totalMap[user] = 0
                                    }
                                    totalMap[user] += a["sum"]
                                }
                                obj1["total_earning"] = tot
                                totalMap["total_earning"] += tot
                                tableData.last_12_months.push(obj1)
                            }
                        }
                        total["last_12_months"] = totalMap
                        fData["last_12_months"]["target_revenue"] = targetRevenue * days_in_month;
                        fData["last_12_months"]["revenue_earned"] = tot1 + tot2;
                        if (employee_type == "general_manager") {
                            fData["last_12_months"]["revenue_earned"] = tot1 + tot2 + tot3 + tot4;
                        }
                        fData["last_12_months"]["underachieved"] = fData["last_12_months"]["target_revenue"] - fData["last_12_months"]["revenue_earned"];
                        tableData.last_30_days.reverse()
                        setTableData(tableData)
                        setForecastData(fData)
                        setSelectedTable(tableData.last_30_days)
                        setTotalRowData(total)
                        setTotalRow([total.last_30_days])
                        setRevenueEarned(fData.last_30_days.revenue_earned)
                        setUnderachieved(fData.last_30_days.underachieved)
                        setForecastTarget(fData.last_30_days.target_revenue)
                        setdailyRate(fData.last_30_days.daily_earning_rate)
                        setweeklyRate(fData.last_30_days.weekly_earning_rate)
                    }
                    else {
                        var tot1 = 0;
                        var pot1 = 0;
                        var psum1 = 0;
                        const data3 = response.data["last_30_days"];
                        var curr_month = 0;
                        for (let i = data3.length - 1; i >= 0; i--) {
                            data3[i].sum = Math.round(data3[i].sum)
                            if (new Date(data3[i].date).getMonth() == new Date().getMonth()) {
                                curr_month += data3[i].sum
                            }
                            tot1 += data3[i].sum;
                            if (data3[i].sum > targetRevenue)
                                pot1 += data3[i].sum;
                            var p = data3[i].sum / targetRevenue * 100;
                            psum1 += p;
                            var logs;
                            try{
                                logs=data3[i].custom_text?JSON.parse(data3[i].custom_text).logs:[]
                            }
                            catch(e){
                                logs=[]
                            }
                            tableData.last_30_days.push([
                                {
                                    "date": new Date(data3[i].date).toLocaleDateString("en-US",),
                                    "target_revenue": targetRevenue,
                                    "target_achieved": data3[i].sum,
                                    "progress": data3[i].sum / targetRevenue * 100,
                                    "logs":logs
                                }])
                        }
                        var tot2 = 0;
                        var pot2 = 0;
                        var psum2 = 0;
                        const data2 = response.data["last_12_weeks"];

                        for (let i = 0; i < data2.length; i++) {
                            data2[i].data = Math.round(data2[i].data)
                            tot2 += data2[i].data;
                            if (data2[i].data > targetRevenue)
                                pot2 += data2[i].data;
                            var p = data2[i].data / targetRevenue * 100;
                            psum2 += p;

                            tableData.last_12_weeks.push([{
                                "date": obj[data2[i].month] + "'W" + data2[i].week,
                                "target_revenue": targetRevenue,
                                "target_achieved": data2[i].data,
                                "progress": p
                            }
                            ])

                        }
                        var tot3 = 0;
                        var pot3 = 0;
                        var psum3 = 0;
                        const data1 = response.data["last_12_months"];

                        for (let i = 0; i < data1.length; i++) {
                            data1[i].sum = Math.round(data1[i].sum)
                            var d = data1[i].c ?
                                data1[i].sum / data1[i].c : data1[i].sum;
                            tot3 += d;
                            if (d > targetRevenue)
                                pot3 += d;
                            var p = d / targetRevenue * 100;
                            psum3 += p;
                            tableData.last_12_months.push([{
                                "date": obj[data1[i].month] + "'" + data1[i].year,
                                "target_revenue": targetRevenue,
                                "target_achieved": d,
                                "progress": d / targetRevenue * 100
                            }])

                        }
                        var fData = {
                            last_30_days: { target_revenue: targetRevenue * days_in_month, revenue_earned: curr_month, underachieved: targetRevenue * days_in_month - curr_month, daily_earning_rate: month_earning, weekly_earning_rate: week_earning },
                            last_12_weeks: { target_revenue: targetRevenue * days_in_month, revenue_earned: tot2, underachieved: targetRevenue * days_in_month - tot2, daily_earning_rate: month_earning, weekly_earning_rate: week_earning },
                            last_12_months: { target_revenue: targetRevenue * days_in_month, revenue_earned: tot3, underachieved: targetRevenue * days_in_month - tot3, daily_earning_rate: month_earning, weekly_earning_rate: week_earning },
                        }
                        var total = {
                            last_30_days: { date: "Total", target_revenue: targetRevenue * data3.length, target_achieved: tot1, progress: tot1 / (targetRevenue * data3.length) * 100 },
                            last_12_weeks: { date: "Total", target_revenue: targetRevenue * data2.length, target_achieved: tot2, progress: tot2 / (targetRevenue * data2.length) * 100 },
                            last_12_months: { date: "Total", target_revenue: targetRevenue * data1.length, target_achieved: tot3, progress: tot3 / (targetRevenue * data1.length) * 100 },
                        }
                        setTableData(tableData)
                        setForecastData(fData)
                        setSelectedTable(tableData.last_30_days)
                        setRevenueEarned(fData.last_30_days.revenue_earned)
                        setUnderachieved(fData.last_30_days.underachieved)
                        setdailyRate(fData.last_30_days.daily_earning_rate)
                        setweeklyRate(fData.last_30_days.weekly_earning_rate)
                        setForecastTarget(fData.last_30_days.target_revenue)
                        setTotalRowData(total)
                        setTotalRow([total.last_30_days])

                    }
                });

    }, [hasSubTeam, targetRevenue, disableSubmit, initial]);

    const handleClick = (selectedRange) => {
        console.log(selectedRangedata)
        console.log(selectedRange)
        const API_ENDPOINT = "".concat(Constants.PROTOCOL,Constants.HOST,"/api_am/data_in_range/");
        const sDate = getFormattedDate(selectedRange.startDate);
        const eDate = getFormattedDate(selectedRange.endDate);
        axios
            .get(API_ENDPOINT, {
                headers: {
                    Authorization: `${token}`,
                },
                params: {
                    user_id:singleAM && is_gm? singleAMId: user_id, start_date: sDate, end_date: eDate, day_range: dayLabel,
                    is_gm: is_gm
                }
            })
            .then((response) => {
                const tableData = [];
                var tot1 = 0;
                var pot1 = 0;
                var psum1 = 0;
                const data3 = response.data;
                var totalMap = {}
                totalMap["date"] = "Total"
                totalMap["total_earning"] = 0
                for (let i = data3.length - 1; i >= 0; i--) {
                    data3[i].sum = Math.round(data3[i].sum)
                    tot1 += data3[i].sum;
                    if (!hasSubTeam) {
                        if (data3[i].sum > targetRevenue)
                            pot1 += data3[i].sum;
                        var p = data3[i].sum / targetRevenue * 100;
                        psum1 += p;
                        var logs;
                        try{
                            logs=data3[i][`${user_id}-logs`]?JSON.parse(data3[i][`${user_id}-logs`]).logs:[]
                        }
                        catch(e){
                            logs=[]
                        }
                        tableData.push([
                            {
                                "date": new Date(data3[i].date).toLocaleDateString("en-US",),
                                "target_revenue": targetRevenue,
                                "target_achieved": data3[i].sum,
                                "progress": data3[i].sum / targetRevenue * 100,
                                "logs":logs
                            }])
                    }
                    else {
                        const objs = data3[i];
                        var obj1 = {};
                        for (const key in objs) {
                            if (objs.hasOwnProperty(key)) {
                                if (key === "date")
                                    obj1["date"] = new Date(data3[i].date).toLocaleDateString("en-US",)
                                else if (key === "sum") {
                                    obj1["total_earning"] = data3[i].sum
                                    totalMap["total_earning"] += data3[i].sum
                                }
                                else {
                                    var user = "" + key
                                    obj1[user] = Math.round(data3[i][key])
                                    if (!totalMap.hasOwnProperty(user)) {
                                        totalMap[user] = 0
                                    }
                                    totalMap[user] += Math.round(data3[i][key])
                                    if(key.includes("logs")){
                                    var logs=[]
                                    try{
                                        logs=data3[i][key]?JSON.parse(data3[i][key]).logs:[]
                                    }
                                    catch(e){
                                        logs=[]
                                    }
                                    obj1[key]=logs
                                }
                                }
                            }
                        }
                        tableData.push(obj1)
                    }
                }
                console.log(tableData)
                var total = hasSubTeam ? totalMap :
                    { date: "Total", target_revenue: (targetRevenue * data3.length).toString(), target_achieved: tot1.toString(), progress: (tot1 / (targetRevenue * data3.length) * 100).toString() };
                setTableData(tableData)
                setSelectedTable(tableData)
                setTotalRow([total])
            });
    }

    const itemsPerPage = itemsOnPage;
    const [currentPage, setCurrentPage] = useState(0);

    const handlePageChange = (selected) => {
        setOffset(itemsPerPage * (selected.selected))
        setCurrentPage(selected.selected);
    };

    const startIdx = currentPage * itemsPerPage;
    const endIdx = (currentPage + 1) * itemsPerPage;
    const dataToDisplay = selectedTable.slice(startIdx, endIdx);

    const submit = async e => {
        e.preventDefault();
        setdisableSubmit(true);
        const API_ENDPOINT =  
        "".concat(Constants.PROTOCOL, Constants.HOST, "/api_am/data/");
        var body = { "data": amount, "date": date, "user_id": manager == "" ? user_id : manager, "manager_id": manager == "" ? "" : user_id,is_gm:is_gm };
        await axios({
            method: "POST",
            url: API_ENDPOINT,
            headers: {
                Authorization: `${token}`,
                'Content-type': 'application/json'
            },
            data: body
        }
        )
            .then((resp) => {
                setdisableSubmit(false);
                setOpen(false);
                if(resp.status==202){
                    setAlert(true);
                }
                else{
                    setAlert(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setdisableSubmit(false)
            });
    }

    const options = [
        { value: "last_30_days", label: "Last 30 Days" },
        { value: "last_12_weeks", label: "Last 12 Weeks" },
        { value: "last_12_months", label: "Last 13 Months" },
    ];

    const IndicatorsContainer = (props) => {
        return <img className="icon1" src={arrowdown} alt="indicator" />;
    };

    const customStyles = {
        container: (base) => ({
            ...base,
            flexGrow: "1",
            margin: "0 1px",
            height: "56px",
            position: "relative",
        }),
        groupHeading: (base) => ({
            color: '#898989',
            fontSize: '0.9rem',
            paddingLeft: '0.4rem',
            // fontFamily: 'kanit,sans-serif'
        }),
        control: (base) => ({
            ...base,
            justifyContent: "start",
            alignItems: "center",
            border: 0,
            boxShadow: "none",
            backgroundColor: "F5F5F5"
        }),
        valueContainer: (base) => ({
            ...base,
            flex: "unset",
            height: "60px",
            fontSize: "1.8rem",
            fontFamily: "Oswald"
        }),
        menu: (base) => ({
            ...base,
            position: "absolute",
            width: "15rem",
            zIndex: "1000",
        }),
        placeholder: (base) => ({
            ...base,
            color: "#252525",
        }),
        option: (styles, { isFocused, isSelected }) => {
            return {
                ...styles,
                color: "#252525",
                backgroundColor: isSelected
                    ? "#E0F1FB"
                    : isFocused
                        ? "#E0F1FB"
                        : undefined,
                textAlign: "left",
                fontSize: "1.5rem",
                fontFamily: "Oswald"
            };
        },
    };

    const getActiveChildLength = (childs) => {
        var childActive = childs.length
        childs.forEach(childItem=>{
            if(childItem.hide){
                childActive -=1
            }
        })
        return childActive
    }
    const CustomSingleValue = ({ children, data, ...props }) => {
        var customLabel;
        customLabel = data.value;
        return (
            <components.SingleValue {...props}>{customLabel}</components.SingleValue>
        );
    };

    function CustomSelect1(props) {
        const handleChange = (option) => {
            const label = options.some(
                (item) => item.value == option.value
            ) ? option.label : "";
            setDateRange(label);
            setSelectedTable(tableData[option.value])
            setCurrentPage(0)
            setOffset(0)
            setTotalRow([totalRowData[option.value]])
        };

        return (
            <Select
                onChange={handleChange}
                img={props.img}
                placeholder={props.placeholder}
                options={props.options}
                isClearable={true}
                components={{
                    IndicatorsContainer: IndicatorsContainer,
                    ValueContainer: props.valueContainer || components.ValueContainer,
                    Option: props.option || components.Option,
                    SingleValue: props.singleValue || components.SingleValue,
                }}
                className="react-select-container"
                classNamePrefix="react-select"
                styles={props.styles}
                isSearchable={false}
                closeMenuOnSelect={false}
            />
        );
    }

    const handleChange2 = (event) => {
        setManager(event.target.value);
    };

    const Progressbar = ({ bgcolor, progress, height, color }) => {

        const Parentdiv = {
            height: height,
            width: '75%',
            backgroundColor: progress >= 90 ? "#0EAE0033" : progress >= 70 ? "#DCBF0033" : "#DF000033",
            borderRadius: 20,
        }

        const Childdiv = {
            height: height,
            width: `${progress}%`,
            backgroundColor: bgcolor,
            borderRadius: 20,
            textAlign: 'left'
        }

        const progresstext = {
            padding: 8,
            color: color,
            fontWeight: 500,
            alignSelf: "flex-end",
            marginRight: "15px",
        }

        return (
            <div style={{display: "flex",
                flexDirection: "column",
                alignItems: "center"}}>
                <span style={progresstext}>{`${Math.round(progress)}%`}</span>
                <div style={Parentdiv}>
                    <div style={Childdiv}>

                    </div>
                </div>
            </div>
        )
    }


    const intValueComponent = (valueItem,item) => {
        if(!valueItem[item.value]){
            return '0'
        }
        return Math.round(valueItem[item.value])
    }

    const userHeaderComponent=(item,index,sortingComponent)=>{
        return <div style={
            !item.child? {width:'calc(100% - 20px'} : 
            {width:'100%'}}>
        <div className='tableHeaderLabel' style={{transition:'all 0.3s linear',display:'flex',alignItems:'center',color:item.child? 
        "#0E6BA8":"#252525",fontSize:item.haschild && showAM? "12px": item.haschild? "14px":"16px",alignItems:"center",whiteSpace:'nowrap',justifyContent:
        item.haschild && showAM?"center":item.label=="Date"?"start":"end"}}
        onClick={item.haschild?() => {setShowAM(!showAM);item.setShowSubTeam(!item.showSubTeam);}:null}>
            {item.label}
            {item.haschild &&
            <img className="icon1" src={showAM? blueArrow:arrowdown} alt="indicator" 
            onClick={item.haschild?() => {setShowAM(!showAM);item.setShowSubTeam(!item.showSubTeam);}:null}
            />}
        </div>
        {item.child && !item.loader &&
            <table style={{fontSize:'12px',paddingTop:'10px',textAlign:'center',width:"100%",tableLayout:'fixed'}}>
                <tr>
                    {item.child.map((childItem,childIndex)=>{
                        // change by sneha
                        if(!childItem.hide){
                            let width = childItem.width ? childItem.width+'%' : parseFloat(100/getActiveChildLength(item.child))+'%'
                            return <td key={childIndex}  style={{width:width,minWidth:width}}>
                                <div style={{display:"flex",justifyContent:'center',alignItems:'center',minWidth:'100%',overflow:'clip',position:'relative'}}> 
                                    <div className='tableHeaderLabel' style={{width:'80%',fontSize:"12px"}}>{childItem.label}</div>
                                </div>
                            </td>
                        }
                    })}
                </tr>
            </table>
        }
    </div>
    }

    const showHistory=(x_val,y_val,logs)=>{
        var data=logs?logs:[]
        setLogData(data)
        setShowLogModal(true)
        setxval(x_val);
        setyval(y_val);
    }


    console.log('deepesh',columnHeadings)
    return <div style={{ padding: "1rem 5rem" }}>
        {alert?  (<Alert severity="error" onClose={() => setAlert(false)}>
        You do not have the permission to edit values. Please ask your manager to make changes.
            </Alert>):(<></>)
        }
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <div className="headingTitle">
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                    }}>
                        <div style={{ marginRight: "auto" }}>
                            Data Entry
                        </div>
                        <img
                            src={cross}
                            style={{
                                width: "1.25rem",
                                height: "1.25rem",
                                margin: "0.25rem"
                            }}
                            onClick={() => handleClose()}
                        />
                    </div>
                </div>
                <form className="form" onSubmit={submit}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                            {hasSubTeam ? (
                                <label className="formLabel">
                                    Manager :
                                </label>) : <></>}
                            <label className="formLabel">
                                Date :
                            </label>
                            <label className="formLabel">
                                Daily Earnings :
                            </label>
                        </div>
                        <div style={{ justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                            {hasSubTeam ? (
                                <div className="formInput">
                                    <select style={{ width: "100%", border: "0px", outline: "0px" }}
                                        value={manager} onChange={handleChange2} required>
                                        <option value="" disabled hidden>Enter Name</option>

                                        {optionsManagersList.map((option) => (
                                            <option value=
                                                {option.value}>{option.label}</option>

                                        ))}

                                    </select>
                                </div>
                            )
                                : <></>}
                            <input className="formInput" type="date" onChange={onDateChange} value={date} max={yes_date.toISOString().split('T')[0]} required/>
                            <input className="formInput" type="number" placeholder="Enter amount ($)" value={amount}
                                onChange={(e) => setAmount(e.target.value)} required/>
                        </div>
                    </div>
                    <button className="formButton" type="submit" >
                        {disableSubmit && <CircularProgress sx={{ color: 'white' }} size={12} />}
                        {!disableSubmit && <div>Save</div>}
                    </button>

                </form>
            </Box>
        </Modal>
        <div className="teamName">
            Data Entry
        </div>
        <div className="parent">
            <div className="child" style={{flex:"2"}}>
                <div className="containerManik" style={{ padding: "1rem 0 0 0" }}>
                    <div>
                        {
                            isModalOpen && <CalenderComponent setSelectedRangedata={setSelectedRangedata}
                                setDayLabel={setDayLabel}
                                handleTable={handleClick}
                                onClick={() => setisModalOpen(false)}
                                isModalOpen={isModalOpen} setisModalOpen={setisModalOpen} />

                        }
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom:"0.6rem"
                    }}>
                        {initial ? (
                            <CustomSelect1
                                placeholder={dateRange}
                                options={options}
                                singleValue={CustomSingleValue}
                                styles={customStyles}
                            />) : (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img
                                    src={back}
                                    style={{
                                        width: "1.3rem",
                                        height: "1.4rem",
                                        marginLeft: "10px",
                                    }}
                                    onClick={() => setInitial(true)}
                                />
                                <div className="teamName" style={{ fontSize: "1.8rem" }}>
                                    {dayLabel}
                                </div>
                            </div>
                        )}

                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginRight: "1rem",
                        }}
                        >

                            <img
                                src={calendar}
                                style={{
                                    width: "2.2rem",
                                    height: "1.4rem",
                                    paddingRight: "0.8rem",
                                    marginTop: "1rem"
                                }}
                                onClick={() => { setisModalOpen(true); setInitial(false) }}
                            />
                            {dateRange == "Last 30 Days" ?
                                (<img
                                    src={editIcon}
                                    style={{
                                        width: "1.3rem",
                                        height: "1.1rem",
                                        marginTop: "1rem"
                                    }}
                                    onClick={() => handleOpen()}
                                />) : (<></>)}

                        </div>
                    </div>
                    <div style={{ width: "100%", overflowX: "scroll" }}>
                        {
                            !loader
                            &&
                            <TableModel
                                tableHeaders={columnHeadings}
                                tableValues={dataToDisplay}
                                setTableValues={setTableData}
                                showSorting={false}
                                totalRow={totalRow}
                                serialOffset={offset}
                                showAM={showAM}
                                setShowAM={setShowAM}
                            />
                        }
                        {
                            showLogModal && 
                            <Modal
                            open={showLogModal}
                            onClose={()=>{setShowLogModal(!showLogModal);setIndex(0)}}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description">
                              <Box sx={modalStyle1}>
                                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",background:"#A5A5A566",padding:"8px"}}>
                              <div style={{fontWeight:"500"}}>
                                  Edit History
                              </div>
                              <div style={{display:"flex",justifyContent:"flex-end"}}>
                              <NavigateBeforeIcon 
                              color={index>=logData.length-1?"disabled":"inherit"}
                              onClick={index>=logData.length-1?null:()=>setIndex(index+1)}/>
                                <NavigateNextIcon
                                color={index<=0?"disabled":"inherit"}
                                 onClick={index<=0?null:()=>setIndex(index-1)}/>
                              </div>
                              </div>
                              {logData.length>0?(
                              <div style={{paddingLeft:"8px",paddingRight:"8px"}}>
                                <List>
                                    <div>
                                    <ListItemText 
                                    primary={allManagerMap.get(logData[index]["changed_by"].toString())}
                                    secondary={new Date((new Date(logData[index].timestamp).getTime())+19800000).toLocaleDateString("en-US",
                                    { month: "short",year:"numeric",day:"numeric"})+
                                    " "+
                                    new Date((new Date(logData[index].timestamp).getTime())+19800000).toLocaleTimeString("en-US",
                                    { timeStyle:"short"})
                                }
                                    >
                                    </ListItemText>
                                    {index==logData.length-1?(
                                    <div> 
                                    Added "{Math.round(logData[index].new_data)}"  
                                    </div>)
                                      :(
                                        <div> 
                                    Changed "{Math.round(logData[index].old_data)}" to "{Math.round(logData[index].new_data)}"  
                                    </div>
                                      )}
                                    </div>
                                </List>
                              </div>):
                              (
                                <div style={{padding:"8px"}}>
                                    No history to show!
                                </div>
                              )}
                              </Box>
                              </Modal>
                        }
                    </div>
                </div>
                <div className="footer">
                    <CustomSelect
                        className="data"
                        placeholder={"Data Limit"}
                        isPlaceholderFixed={true}
                        options={[
                            { value: 5, label: 5 },
                            { value: 10, label: 10 },
                            { value: 50, label: 50 },
                        ]}
                        setItemsOnPage={setItemsOnPage}
                        itemsOnPage={itemsOnPage}
                        setCurrentPage={setCurrentPage}
                        setOffset={setOffset}
                        width="7rem"
                        maxHeight="25px"
                        color="#FFF9F9"
                        bgcolor="#0E6BA8"
                        img={whiteArrow}
                        menu={true}
                    />
                    <ReactPaginate
                        pageCount={Math.ceil(selectedTable.length / itemsPerPage)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={0}
                        initialPage={currentPage}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        activeClassName={"activeManik"}
                        pageLinkClassName={"page-number"}
                        previousLabel={
                            <img
                                src={leftArrow}
                                alt="Previous"
                                style={{ marginTop: "5px", width: "10px", height: "15px" }}
                            />
                        }
                        breakLabel={false}
                        nextLabel={
                            <img
                                src={rightArrow}
                                alt="Next"
                                style={{ marginTop: "5px", width: "10px", height: "15px" }}
                            />
                        }
                    />
                </div>
            </div>
            <div className="containerManik child" style={{ marginLeft: "1rem" }}>
                <div className="title" style={{ marginBottom: "1rem", paddingLeft: "10px", marginRight: "auto", marginTop: "1rem", }}>
                    Data Summary{" "}
                    <span
                        style={{
                            marginLeft: "0.2rem",
                            marginTop: "0.5rem",
                            color: "#A5A5A5",
                        }}>
                        ({new Date().toLocaleString('en-us', { month: 'short', year: 'numeric' })})
                    </span>
                </div>
                <div>
                    <div className="forecast">
                        <div className="forecast-labels">Target Earning</div>
                        <div className="forecast-values">{Math.round(forecastTarget)}</div>
                    </div>
                    <div className="forecast">
                        <div className="forecast-labels">Earning Achieved</div>
                        <div className="forecast-values">{Math.round(revenueEarned)}</div>
                    </div>
                    <div className="forecast">
                        <div className="forecast-labels">Target Pending</div>
                        <div className="forecast-values">{Math.round(underachieved)}</div>
                    </div>
                    <div className="forecast">
                        <div className="forecast-labels">Daily Earning Rate</div>
                        <div className="forecast-values">{Math.round(dailyRate)}</div>
                    </div>
                    <div className="forecast">
                        <div className="forecast-labels">Weekly Earning Rate</div>
                        <div className="forecast-values">{Math.round(weeklyRate)}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default ManagerDataEntry;